<template>
  <LoadScript
    v-if="showDsfVtoWrapper"
    :attributes="{ type: 'module' }"
    :src="scriptSource"
    @loaded="onDropperLoad"
  >
    <dsf-app v-bind="config" />
  </LoadScript>
</template>

<script>
import { SERVICE_BUTTON_CLASS, SERVICE_TRIGGER_URL_PARAM_KEY } from '../../settings';
import { mixinParseURL } from '@Foundation/mixins/parseURL/parseURL';
import LoadScript from '../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';

export default {
  name: 'OapDsfAppVtoWrapper',

  components: {
    LoadScript,
  },

  mixins: [mixinParseURL],

  props: {
    config: { type: Object, require: true },
    scriptSource: { type: String, default: 'https://dsf-cdn.loreal.io/dropper.js' },
  },

  data() {
    return {
      showDsfVtoWrapper: false,
    };
  },

  created() {
    window.addEventListener('load', () => {
      this.showDsfVtoWrapper = true;
    });
  },

  methods: {
    onDropperLoad() {
      window.dsfApp?.addEventListener('onLoadComplete', this.onLoadComplete);
    },

    onLoadComplete() {
      const clickServiceButton = document.body.querySelector(`.${SERVICE_BUTTON_CLASS}`);

      if (this.URLHasKey(SERVICE_TRIGGER_URL_PARAM_KEY)) {
        clickServiceButton.click();
      }
    },
  },
};
</script>
