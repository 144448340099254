<template>
  <div id="filterList" class="filterList">
    <div
      v-if="filterDataset.resultsString"
      :class="[
        filterDataset.gridType === 'ProductsList'
          ? 'container--plp'
          : filterDataset.gridType === 'ProductsListTwoColumns'
          ? 'container--plp container--plp-two-columns'
          : 'container',
      ]"
    >
      <div class="filterList__panelWrapper">
        <div class="filterList__panel">
          <div id="filterList__sort" class="filterList__sort">
            <span class="filterList__qty">
              <span role="status">{{ filterDataset.resultsString }}</span>
            </span>
            <template v-if="showFilterOptions">
              <FilterSorting
                :grid-type="filterDataset.gridType"
                :sorting-label="filterDataset.dictionary.sortingLabel"
                :sorting-options="filterDataset.sortingOptions"
              />
            </template>
            <template v-if="filterDataset.gridType === 'ProductsListTwoColumns'">
              <button class="filterList__toggleFilterAccordion" @click="dialogIsOpen = true">
                {{ filterDataset.dictionary.filterTitle }}
                {{ selectedTags.length === 0 ? '' : `(${selectedTags.length})` }}
              </button>
            </template>
          </div>

          <button
            v-if="filterDataset.gridType !== 'ProductsListTwoColumns'"
            id="toggle-facets"
            class="filterList__toggleFacets"
            :class="{ '-expanded': facetsOpened }"
            :aria-expanded="facetsOpened.toString()"
            @click="onToggleFacets"
          >
            <span
              v-if="filterDataset.dictionary && filterDataset.dictionary.filterTitle"
              class="filterList__toggleText"
            >
              <span v-if="!facetsOpened"> {{ filterDataset.dictionary.filterTitle }}</span>
              <span v-if="facetsOpened"> {{ filterDataset.dictionary.expandedFilterTitle }}</span>
              <svg v-if="!facetsOpened" class="filterList__toggleIcon" :aria-hidden="true">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#filter" />
              </svg>
              <svg v-if="facetsOpened" class="filterList__toggleIcon" :aria-hidden="true">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close" />
              </svg>
            </span>
          </button>
        </div>
        <template v-if="filterDataset.gridType !== 'ProductsListTwoColumns'">
          <Facets v-if="facetsOpened" :facet-dataset="filterDataset" />
          <selected-filters
            v-if="!facetsOpened"
            :selected-dataset="filterDataset"
          ></selected-filters>
          <div v-show="showOverlay" class="loadingOverlay">
            <div class="loaderContainer">
              <OapLoader />
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      :class="[
        {
          'container--plp':
            filterDataset.gridType === 'ProductsList' ||
            filterDataset.gridType === 'ProductsListTwoColumns',
        },
        { 'container--plp-two-columns': filterDataset.gridType === 'ProductsListTwoColumns' },
      ]"
    >
      <template v-if="filterDataset.gridType === 'ProductsListTwoColumns' && !smallScreen">
        <div class="container--plp-two-columns__wrapper">
          <div class="container--plp-two-columns__side-col">
            <FilterShortcutLinks
              v-if="initialdata.shortcutLinks?.list?.length"
              :shortcuts="initialdata.shortcutLinks"
            ></FilterShortcutLinks>
            <FilterAccordion
              :initial-data="initialdata"
              :tags-data="{
                tagsCollection: tagsCollection,
                selectedTags: selectedTags,
                selectedBrands: selectedBrands,
              }"
              :show-overlay="showOverlay"
              no-loader
            ></FilterAccordion>
          </div>
          <div class="container--plp-two-columns__main">
            <div v-if="showOverlay" class="container--plp-two-columns__overlay"></div>
            <Grid :display-review-count="displayReviewCount" :grid-dataset="filterDataset" />
            <Loadmore :grid-dataset="filterDataset" />
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="showOverlay" class="container--plp-two-columns__overlay"></div>
        <Grid :display-review-count="displayReviewCount" :grid-dataset="filterDataset" />
        <Loadmore :grid-dataset="filterDataset" />
      </template>
    </div>

    <div
      v-if="filterDataset.banner && filterDataset.banner.textField"
      class="rich-text filterTextField"
      v-html="filterDataset.banner.textField"
    ></div>
    <div
      v-else-if="initialdata.textField"
      class="rich-text filterTextField"
      v-html="initialdata.textField"
    ></div>
    <OapFocusTrap :is-visible="dialogIsOpen">
      <transition name="modal">
        <div v-if="dialogIsOpen" role="dialog" aria-modal="true">
          <div class="container--plp-two-columns__filter-dialog">
            <FilterAccordion
              :initial-data="initialdata"
              :tags-data="{
                tagsCollection: tagsCollection,
                selectedTags: selectedTags,
                selectedBrands: selectedBrands,
              }"
              :show-overlay="showOverlay"
            ></FilterAccordion>
          </div>
        </div>
      </transition>
    </OapFocusTrap>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, createStore } from 'vuex';
import store from '../store';

import { MqHandler } from '../../../../../Foundation/Core/code/Scripts/mqHandler';
import eventBus from '@loreal/eventbus-js';
import FilterAccordion from './filter-accordion.vue';
import Facets from './facets.vue';
import FilterSorting from './filter-sorting.vue';
import Grid from './grid.vue';
import FilterShortcutLinks from './filter-shortcut-links.vue';
import OapFocusTrap from '../../../../OapFocusTrap/code/Scripts/components/OapFocusTrap.vue';
import OapLoader from '../../../../OapLoader/code/Scripts/components/OapLoader.vue';
import { HERO_VIDEO_UPDATE } from '../constants/eventNames';
import Loadmore from './load-more.vue';

export default {
  name: 'FilterList',

  components: {
    Facets,
    FilterSorting,
    FilterAccordion,
    FilterShortcutLinks,
    Grid,
    OapFocusTrap,
    OapLoader,
    Loadmore,
  },

  props: {
    displayReviewCount: {
      default: false,
      type: Boolean,
    },
    initialdata: { type: Object, required: true },
  },

  data() {
    return {
      smallScreen:
        MqHandler.getMqHandler().getMqForSize('small').selected ||
        MqHandler.getMqHandler().getMqForSize('medium').selected,
      dialogIsOpen: false,
    };
  },

  computed: {
    ...mapState('filterList', {
      filterDataset: (state) => state.filterDataset,
      showOverlay: (state) => state.loading,
      facetsOpened: (state) => state.facetsOpened,
    }),
    ...mapGetters('filterList', ['tagsCollection', 'selectedTags', 'selectedBrands']),
    showFilterOptions() {
      return this.filterDataset.sortingOptions.length > 0 && this.filterDataset.list.length > 0;
    },
  },

  watch: {
    showOverlay(val) {
      if (val && this.facetsOpened) {
        document.body.classList.add('noScroll');
      } else {
        document.body.classList.remove('noScroll');
      }
    },
  },

  beforeCreate() {
    const localStore = createStore({});
    localStore.registerModule('filterList', store);
  },

  created() {
    this.setInitialItems(this.initialdata);
  },

  mounted() {
    eventBus.on('mediaquery::changed', (mq) => {
      this.smallScreen = !!(mq.size === 'small' || mq.size === 'medium');

      if (this.facetsOpened) {
        this.toggleFacets();
      }
    });

    eventBus.on('filterProducts', (payload) => {
      const { data } = payload;
      if (!data) return;
      this.setInitialItems({ clearAllFilter: true });
      this.getData(data.apiUrl, payload.url ? payload.url : false);
    });

    eventBus.on('resetFilterProducts', ({ url = false, apiURL }) => {
      this.setInitialItems({ clearAllFilter: true });
      this.getData(apiURL, url);
    });

    eventBus.on('closeFilterProductsDialog', () => {
      this.dialogIsOpen = false;
    });

    window.onpopstate = () => {
      window.location.href = document.location;
    };
  },

  methods: {
    ...mapActions('filterList', ['setInitialItems', 'getNewItems', 'toggleFacets', 'setLoadMore']),

    onToggleFacets() {
      this.toggleFacets();
      if (!this.facetsOpened) {
        eventBus.emit(HERO_VIDEO_UPDATE);
      }
    },

    getData(apiUrl, pushState = false) {
      return this.getNewItems(apiUrl).then(() => this.pushUrl(pushState || apiUrl));
    },

    pushUrl(url) {
      window.history.pushState({}, document.title, url);

      this.updateGoogleBotParameters(url);
    },

    updateGoogleBotParameters(url) {
      let metaRobots = document.querySelector('meta[name="robots"]');

      if (!metaRobots) {
        metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        document.head.appendChild(metaRobots);
      }

      metaRobots.setAttribute('content', 'noindex, follow');

      let canonicalLink = document.querySelector('link[rel="canonical"]');

      if (!canonicalLink) {
        canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        document.head.appendChild(canonicalLink);
      }

      canonicalLink.setAttribute('href', `${window.location.origin}${url}`);
    },
  },
};
</script>
